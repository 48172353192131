import { create } from "zustand";
import { useCommonSlice } from "./commonSlice";
import { useFetchAppointmentsSlice } from "../../appointmentModuleStore/fetchAppointmentSlice";
import { useFetchDoctorsForAppointmentSlice } from "../../appointmentModuleStore/fetchDoctorsForAppointmentSlice";
import { useFetchDoctorSlotsSlice } from "../../appointmentModuleStore/fetchDoctorSlotsSlice";
import { useCreateAppointmentlice } from "../../appointmentModuleStore/createAppointmentSlice";
import { useFetchCalendarCountSlice } from "../../appointmentModuleStore/fetchCalendarCountSlice";
import { usecancelAppointmentlice } from "../../appointmentModuleStore/cancelAppointmentSlice";
import { useEditAppointmentSlice } from "../../appointmentModuleStore/editAppointmentSlice";
import { useConfirmAppointmentSlice } from "../../appointmentModuleStore/confirmAppointmentSlice";
import { useUploadPrescrtiptionSlice } from "../../appointmentModuleStore/uploadPrescriptionSlice";
import { useFetchAppointmentByIdSlice } from "../../appointmentModuleStore/getAppointmentByIdSlice";

interface AppointmentDetailsStoreType
  extends ReturnType<typeof useCommonSlice>,
    ReturnType<typeof useFetchAppointmentsSlice>,
    ReturnType<typeof useFetchDoctorsForAppointmentSlice>,
    ReturnType<typeof useFetchDoctorSlotsSlice>,
    ReturnType<typeof useCreateAppointmentlice>,
    ReturnType<typeof useFetchCalendarCountSlice>,
    ReturnType<typeof usecancelAppointmentlice>,
    ReturnType<typeof useConfirmAppointmentSlice>,
    ReturnType<typeof useUploadPrescrtiptionSlice>,
    ReturnType<typeof useFetchAppointmentByIdSlice>,
    ReturnType<typeof useEditAppointmentSlice> {}

export const useAppointmentDetailsStore = create<AppointmentDetailsStoreType>()(
  (...appointmentDetails) => ({
    ...useCommonSlice(...appointmentDetails),
    ...useFetchAppointmentsSlice(...appointmentDetails),
    ...useFetchDoctorsForAppointmentSlice(...appointmentDetails),
    ...useFetchDoctorSlotsSlice(...appointmentDetails),
    ...useCreateAppointmentlice(...appointmentDetails),
    ...useFetchCalendarCountSlice(...appointmentDetails),
    ...usecancelAppointmentlice(...appointmentDetails),
    ...useEditAppointmentSlice(...appointmentDetails),
    ...useConfirmAppointmentSlice(...appointmentDetails),
    ...useUploadPrescrtiptionSlice(...appointmentDetails),
    ...useFetchAppointmentByIdSlice(...appointmentDetails),
  })
);
