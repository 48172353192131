import { create } from "zustand";
import { USER_LOGIN_API } from "../../base";
import { LocalLoggedInUser } from "../../data/usecases/local-logged-in-user";
import { RemoteUserLogin } from "../../data/usecases/remote-user-login";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { LoginItems, LoginUser } from "../../domain/models/user-login-type";
import Swal from "sweetalert2";
import { useLoginStore } from "./loginStore";
import { pageRoutes } from "../../routes";
import { useNavigate } from "react-router-dom";

interface LoginPageStore {
  handleLogin: (data: LoginItems) => void;
  loginDetails: LoginUser;
  handleLogOut: Function;
  loginFailed: boolean;
  loginLoading:boolean
}

const initialStates = {
  loginFailed: false,
  loginLoading:false,
  loginDetails: {} as LoginUser,
};


export const useLoginPageStore = create<LoginPageStore>((set, get) => ({
  ...initialStates,
  handleLogOut: () => {
    set(() => ({ loginDetails: {} as LoginUser }));
  },
  handleLogin: async (data: LoginItems) => {

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();
const loggedInUser = new LocalLoggedInUser(storage);
const remoteUserLogin = new RemoteUserLogin(
  `${USER_LOGIN_API}${Endpoints.USER_LOGIN}`,
  axiosHttpClient
);
    let payload = {
      username: data.Username,
      password: data.Password,
    };
    set(() => ({ loginLoading:true }));
    let result = await remoteUserLogin.login(payload);
    if (result.success) {
      loggedInUser.setToken(result.user.auth_token);
      loggedInUser.setPSPToken(result.user.psp_auth_token);
      loggedInUser.setUserDetails(result.user);
      set(() => ({ loginDetails: result.user, loginFailed: false }));
      set(() => ({ loginLoading:false }));
      useLoginStore.getState().generateToken();
    } else {
      set(() => ({ loginLoading:false }));
      set(() => ({ loginFailed: true }));
      Swal.fire({
        title: result?.errors?.message,
        icon: "error",
        timer: 2000,
      });
    }
  },
}));
