export const toTitleCase = (str: string): string => {
  if(str){
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }
  return ""
  
};

export const getDomain = (): string => {
  return window.location.hostname;
};

export const filterAddress = (
  line: string | null,
  landmark: string | null,
  area: string | null,
  city: string | null,
  district: string | null,
  state: string | null,
  pincode: string | null,
  country: string | null
) => {
  const addressParts: (string | number | null)[] = [
    line,
    landmark,
    area,
    city,
    district,
    state,
    pincode,
    country,
  ].filter((part) => part !== null && part !== "");

  return addressParts.join(", ");
};
