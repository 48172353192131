import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import { RemoteFetchPrograms } from "../../../../data/usecases/remote-fetch-programs";
import Endpoints from "../../../../domain/endpoints";
import { ProgramOptionType } from "../../../../domain/models/program-option-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { FetchPrograms } from "../../../../domain/usages/fetch-programs";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";
import { RemoteMasterDropdownData } from "../../../../data/usecases/remote-master-dropdown-data";
import { MasterDropdownData } from "../../../../domain/usages/master-dropdown-data";
import { MasterDropdownDataType } from "../../../../domain/models/masterDropdownData";

interface MasterDropdownDataSliceType {
  fetchMasterDropdownData: Function;
  masterDropdownData: MasterDropdownDataType;
  masterDropdownDataLoading: boolean;
}
const initialStates = {
  masterDropdownData: {} as MasterDropdownDataType,
  masterDropdownDataLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useMasterDropdownDataSlice: StateCreator<
  MasterDropdownDataSliceType
> = (set, get) => ({
  ...initialStates,
  fetchMasterDropdownData: async (id: number) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteMasterDropdownData: MasterDropdownData =
      new RemoteMasterDropdownData(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.MASTER_DROPDOWN_DATA}`,
        axiosHttpClient
      );
    set(() => ({ masterDropdownDataLoading: true }));
    let result = await remoteMasterDropdownData.fetch(id.toString());
    if (result.success) {
      set(() => ({
        masterDropdownData: result.master_data,
        masterDropdownDataLoading: false,
      }));
    } else if (result == 404) {
      get().fetchMasterDropdownData(id.toString());
    }
  },
});
