import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  APPOINTMENT_SERVICE_STAGING_URL,
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Constant } from "../../../constants/constant";
import { CancelAppointmentResponse } from "../../../domain/models/appointment-module/cancel-appointment-response";
import { RemoteUploadPrescription } from "../../../data/usecases/appointment-module/remote-upload-prescription";
import { Prescription } from "../../../domain/usages/appointment-module/upload-prescription";
import { useAppointmentDetailsStore } from "../patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";

interface uploadPrescrtiptionSliceInterface {
  uploadPrescrtiption: Function;
  uploadPrescrtiptionLoading: FetchState;
}

const initialStates = {
  uploadPrescrtiptionLoading: FetchState.DEFAULT,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUploadPrescrtiptionSlice: StateCreator<
  uploadPrescrtiptionSliceInterface
> = (set, get) => ({
  ...initialStates,
  uploadPrescrtiption: async (
    appointment_id: number,
    documents: Prescription
  ) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });
    const remoteUploadPrescrtiption = new RemoteUploadPrescription(
      `${APPOINTMENT_SERVICE_STAGING_URL}${Endpoints.UPLOAD_PRESCRIPTION}`,
      axiosHttpClient
    );
    Swal.fire({
      title: "Response Loading...",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const payload = {
      documents: [documents],
    };

    set(() => ({ uploadPrescrtiptionLoading: FetchState.LOADING }));
    let result: CancelAppointmentResponse =
      await remoteUploadPrescrtiption.upload(appointment_id, payload);

    if (result) {
      if (result.success) {
        set(() => ({ uploadPrescrtiptionLoading: FetchState.SUCCESS }));

        useAppointmentDetailsStore
          .getState()
          .fetchAppointmentsById(appointment_id);
        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      } else {
        Swal.fire("Appointment Cancellation failed.", "", "error");
      }
    } else {
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
    }
  },
});
