import { Box, Modal, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { usePersonalDetailStore } from "../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useProgramDetailStore } from "../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { useSendToCFAStore } from "../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import { EnrollmentDetails } from "../../../domain/models/create-enrollment";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Constant } from "../../../constants/constant";
import moment from "moment";
import { Dayjs } from "dayjs";
import { ProgramInitiator } from "../../../constants/enums/programInitiator";
import { getDomain } from "../../../domain/Utils";
import DomainName from "../../../domain/domain";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: "22px",
  boxShadow: 24,
  p: 4,
};

const AddNewEnrollment: React.FC = () => {
  const [openDate, setOpenDate] = useState<boolean>(false);
  const { handleCloseEnrollmentModal, addNewEnrollment, createEnrollment } =
    useEnrollmentMainStore();
  const { handleSubmit, control, setValue, watch } = useForm<EnrollmentDetails>(
    {
      mode: "onChange",
    }
  );
  const { productOptions, fetchProductOptions } = useSendToCFAStore();
  const { handlePincode, district, state, country, city, handleRefreshState } =
    usePersonalDetailStore();
  const { programOptions } = useProgramDetailStore();

  const handleCloseModal = () => {
    handleCloseEnrollmentModal();
  };

  const handleClick = (data: EnrollmentDetails) => {
    let payload = {
      initiator: ProgramInitiator.ADMIN,
      patient_caretaker_name: null,
      hospital_coordinator_name: null,
      patient_full_name: data.fullName,
      patient_status: null,
      email: data.email,
      mobile: data.contact,
      gender: data.gender,
      demo_confirmation: data.demoConfirmation ? 1 : 0,
      program_id: data.selectedProgram,
      product_id: data.selectedProduct,
      prescription_uuid: null,
      address_proof_uuid: null,
      identity_uuid: null,
      date_of_birth: data.dateOfBirth
        ? moment((data.dateOfBirth as Dayjs).toString()).format(
            Constant.YEAR_MONTH_DATE_FORMAT
          )
        : null,
      age: data.age,
      address: {
        pin_code: data.address.pincode,
        district: district,
        state: state,
        line: data.address.line,
        landmark: data.address.landmark,
        country: country,
        city: city,
      },
      doctor_full_name: null,
      hospital_name: null,
      consent_accepted: false,
    };
    if (payload) {
      createEnrollment(payload);
    }
  };

  useEffect(() => {
    setValue("selectedProgram", null);
    setValue("selectedProduct", null);
    setValue("fullName", "");
    setValue("gender", "");
    setValue("contact", "");
    setValue("address.line", "");
    setValue("address.landmark", "");
    setValue("address.pincode", "");
    setValue("dateOfBirth", null);
    setValue("age", null);
    handleRefreshState();
  }, [addNewEnrollment]);

  useEffect(() => {
    if (watch("dateOfBirth")) {
      calculateAge();
    }
  }, [watch("dateOfBirth")]);

  useEffect(() => {
    if (watch("selectedProgram")) {
      let params = {
        program_id: watch("selectedProgram"),
      };
      fetchProductOptions(params);
    }
  }, [watch("selectedProgram")]);

  const showDemoConfirmField = () => {
    const showDemoField = programOptions.find(
      (item) => item.id.toString() == watch("selectedProgram")
    );
    if (showDemoField) {
      if (showDemoField.demoable == 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const calculateAge = () => {
    const today = new Date();
    const birthDate = new Date(
      moment((watch("dateOfBirth") as Dayjs).toString()).format("YYYY-MM-DD")
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setValue("age", age);
  };

  const filterProgramOptions = () => {
    if (getDomain() == DomainName.ASTELLAS) {
      const program = programOptions.filter((item) => item.id == 2);
      return program;
    } else if (getDomain() == DomainName.OSTEO) {
      const program = programOptions.filter((item) => item.id == 1);
      return program;
    } else if (getDomain() == DomainName.EISAI) {
      const program = programOptions.filter((item) => item.id == 3);
      return program;
    } else {
      return programOptions;
    }
  };
  return (
    <Modal open={addNewEnrollment} onClose={() => handleCloseEnrollmentModal()}>
      <Box sx={[style]}>
        <Typography variant="h6" fontWeight={600} gutterBottom>
          Enrollment Form
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name="selectedProgram"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  select
                  fullWidth
                  label="Select Program"
                  value={value}
                  onChange={onChange}
                  error={error && true}
                  helperText={error?.message}
                >
                  {filterProgramOptions().map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="selectedProduct"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  select
                  fullWidth
                  label="Select Product"
                  value={value}
                  onChange={onChange}
                  error={error && true}
                  helperText={error?.message}
                >
                  {productOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="fullName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Patient Name"
                  value={value}
                  onChange={onChange}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="gender"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  select
                  label="Patient Gender"
                  value={value}
                  onChange={onChange}
                  error={error && true}
                  helperText={error?.message}
                >
                  <MenuItem key={"male"} value={"male"}>
                    Male
                  </MenuItem>
                  <MenuItem key={"female"} value={"female"}>
                    Female
                  </MenuItem>
                  <MenuItem key={"other"} value={"others"}>
                    Other
                  </MenuItem>
                </TextField>
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name="dateOfBirth"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      open={openDate}
                      onClose={() => {
                        setOpenDate(false);
                      }}
                      onOpen={() => {
                        setOpenDate(true);
                      }}
                      disableFuture
                      value={value}
                      onChange={onChange}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="age"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  value={value}
                  placeholder="Age"
                  onChange={(event: any) => {
                    onChange(event.target.value);

                    setValue("dateOfBirth", null);
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
                pattern: {
                  value: Constant.NUMBER_ONLY.VALUE,
                  message: Constant.NUMBER_ONLY.MESSAGE,
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="email"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Email"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              rules={{
                pattern: {
                  value: Constant.CORRECT_EMAIL.VALUE,
                  message: Constant.CORRECT_EMAIL.MESSAGE,
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="contact"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Mobile Number"
                  value={value}
                  onChange={onChange}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
                pattern: {
                  value: Constant.CORRECT_MOBILE.VALUE,
                  message: Constant.CORRECT_MOBILE.MESSAGE,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight={600}>
              Address:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="address.line"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Line"
                  value={value}
                  onChange={onChange}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="address.landmark"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Landmark"
                  value={value}
                  onChange={onChange}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="address.pincode"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Pincode"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);

                    handlePincode(e.target.value);
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
                pattern: {
                  value: /^[1-9]{1}\d{2}\s?\d{3}$/,
                  message: "Enter 6 digits pincode",
                },
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="address.city"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  fullWidth
                  label="City"
                  value={city}
                  InputProps={{
                    readOnly: true,
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="address.district"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  fullWidth
                  label="District"
                  value={district}
                  InputProps={{
                    readOnly: true,
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="address.state"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  fullWidth
                  label="State"
                  value={state}
                  InputProps={{
                    readOnly: true,
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="address.country"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  fullWidth
                  label="Country"
                  value={country}
                  InputProps={{
                    readOnly: true,
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        {showDemoConfirmField() && (
          <FormGroup>
            <Controller
              name="demoConfirmation"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label="Demo Confirmation"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </FormGroup>
        )}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          marginTop={1}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleClick)}
          >
            Submit
          </Button>
          <Button variant="outlined" color="error" onClick={handleCloseModal}>
            Close
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddNewEnrollment;
