import {
  Typography,
  Grid,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import moment from "moment";
import dayjs from "dayjs";
import { useEnrollmentMainStore } from "../../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useProgramDetailStore } from "../../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { useSendToCFAStore } from "../../../../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import { ProgramItems } from "../../../../../../domain/models/program-items-type";
import { getDomain } from "../../../../../../domain/Utils";
import DomainName from "../../../../../../domain/domain";
import { Constant } from "../../../../../../constants/constant";

const TreatmentCycleArr = [
  {
    name: "Daily",
    value: "daily",
  },
  {
    name: "Weekly",
    value: "weekly",
  },

  {
    name: "3 Weekly",
    value: "3 Weekly",
  },
  {
    name: "Fortnightly",
    value: "fortnightly",
  },
  {
    name: "Every 28 Days",
    value: "every 28 days",
  },
  {
    name: "Monthly",
    value: "monthly",
  },
];

const EisaiEditProgram: React.FC = () => {
  const { selectedCard } = useEnrollmentMainStore();
  const {
    handleEdit,
    onUpdate,
    masterDropdownData,
    masterDropdownDataLoading,
  } = useProgramDetailStore();
  const { productOptions, fetchProductOptions } = useSendToCFAStore();

  const fetchDiagnosis = (productId: number, programId: number) => {
    const diagnosis = masterDropdownData.master_diagnosis.filter(
      (item) =>
        (item.product_id == productId ||
          item.product_id == selectedCard.product_id) &&
        item.program_id == programId
    );
    return diagnosis ?? [];
  };

  const fetchDosage = (productId: number, programId: number) => {
    const dosage = masterDropdownData.master_dosage.filter(
      (item) =>
        (item.product_id == productId ||
          item.product_id == selectedCard.product_id) &&
        item.program_id == programId
    );
    return dosage ?? [];
  };

  const fetchPackScheme = (programId: number) => {
    const packScheme = masterDropdownData.master_schemes.filter(
      (item) => item.program_id == programId
    );
    return packScheme ?? [];
  };

  const { handleSubmit, control, watch, setValue } = useForm<ProgramItems>({
    mode: "onChange",
  });

  useEffect(() => {
    setValue(
      "transplant_date",
      selectedCard.transplant_date ? dayjs(selectedCard.transplant_date) : null
    );
    setValue("treatment", selectedCard.treatment);
    setValue("nationality", selectedCard.is_indian_national);

    setValue("diagnosis", selectedCard.diagnosis);
    setValue("dosage", selectedCard.dosage);
    setValue("uom", selectedCard.uom);
    setValue("alt_mobile", selectedCard.alt_mobile);
    setValue(
      "doctor_full_name",
      selectedCard.doctor_full_name ? selectedCard?.doctor_full_name : " "
    );
    setValue("hospital", selectedCard.hospital ? selectedCard?.hospital : "");
    setValue("treatment_cycle", selectedCard.treatment_cycle);
    setValue(
      "prescription_date",
      selectedCard.prescription_date
        ? dayjs(selectedCard.prescription_date)
        : null
    );
    setValue(
      "treatment_start_date",
      selectedCard.treatment_start_date
        ? dayjs(selectedCard.treatment_start_date)
        : null
    );
    setValue(
      "next_free_cycle_date",
      selectedCard.next_free_cycle_date
        ? dayjs(selectedCard.next_free_cycle_date)
        : null
    );
    setValue("no_of_therapy_months", selectedCard.no_of_therapy_months);
    setValue("no_of_free_months", selectedCard.no_of_free_months);

    setValue("comments", selectedCard.comments);
    let params = {
      program_id: selectedCard.program_id,
    };
    fetchProductOptions(params);
  }, [selectedCard.id]);

  const handleClick = (data: ProgramItems) => {
    let payload = {
      product_id: data.product_id ? data.product_id : selectedCard.product.id,
      dosage: data.dosage,
      uom: 0,
      hospital: data.hospital,
      alt_mobile: data.alt_mobile,
      doctor_full_name: data.doctor_full_name,
      treatment: " ",
      pack_scheme: data.pack_scheme,
      is_indian_national: data.nationality,
      treatment_cycle: data.treatment_cycle,
      diagnosis: data.diagnosis,
      transplant_date:
        data.transplant_date &&
        moment(data.transplant_date.toString()).format("YYYY-MM-DD"),
      prescription_date:
        data.prescription_date &&
        moment(data.prescription_date.toString()).format("YYYY-MM-DD"),
      treatment_start_date:
        data.treatment_start_date &&
        moment(data.treatment_start_date.toString()).format("YYYY-MM-DD"),

      comments: data.comments,
    };
    onUpdate(payload);
  };

  return (
    <Stack overflow="scroll" height="51vh">
      <Stack
        alignItems={"center"}
        direction={"row"}
        justifyContent={"space-between"}
        width={"42%"}
        position={"fixed"}
        zIndex={10}
        bgcolor={"white"}
        paddingBottom={2}
      >
        <Stack>
          <Typography>Program Details</Typography>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Button
            sx={{ borderRadius: "45px" }}
            variant="contained"
            onClick={handleSubmit(handleClick)}
          >
            Update
          </Button>
          <Button
            color="secondary"
            variant="text"
            onClick={() => handleEdit(0)}
          >
            CLOSE
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={3} mt={3}>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Product
          </Typography>
          <Controller
            name="product_id"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                size="small"
                select
                defaultValue={selectedCard.product.id}
                fullWidth
                value={value}
                onChange={onChange}
                error={error && true}
                helperText={error?.message}
              >
                {productOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Secondary Contact
          </Typography>
          <Controller
            name="alt_mobile"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
            rules={{
              pattern: {
                value: Constant.CORRECT_MOBILE.VALUE,
                message: Constant.CORRECT_MOBILE.MESSAGE,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="nationality"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl>
                <Stack
                  flexDirection={"row"}
                  gap={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    <Typography color={"text.secondary"} variant="caption">
                      Is an Indian National?
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value={1}
                      checked={value == 1}
                      control={<Radio size="small" />}
                      label={<Typography variant="caption">Yes</Typography>}
                    />
                    <FormControlLabel
                      value={0}
                      checked={value == 0}
                      control={<Radio size="small" />}
                      label={<Typography variant="caption">No</Typography>}
                    />
                  </RadioGroup>
                </Stack>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Pack Scheme
          </Typography>
          <Controller
            name="pack_scheme"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                select
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                defaultValue={selectedCard.pack_scheme}
                helperText={error?.message}
                onChange={onChange}
              >
                {fetchPackScheme(selectedCard.program_id).length > 0 &&
                  !masterDropdownDataLoading &&
                  fetchPackScheme(selectedCard.program_id).map((option) => (
                    <MenuItem key={option.program_id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Doctor
          </Typography>
          <Controller
            name="doctor_full_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color={"text.secondary"}>
            Hospital/Clinic
          </Typography>
          <Controller
            name="hospital"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Diagnosis
          </Typography>
          <Controller
            name="diagnosis"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                select
                variant="outlined"
                value={value}
                size="small"
                defaultValue={selectedCard.diagnosis}
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              >
                {fetchDiagnosis(watch("product_id"), selectedCard.program_id)
                  .length > 0 &&
                  !masterDropdownDataLoading &&
                  fetchDiagnosis(
                    watch("product_id"),
                    selectedCard.program_id
                  ).map((option) => (
                    <MenuItem key={option.program_id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Treatment Cycle
          </Typography>
          <Controller
            name="treatment_cycle"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                defaultValue={selectedCard.treatment_cycle}
                fullWidth
                select
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              >
                {TreatmentCycleArr.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Dosage
          </Typography>
          <Controller
            name="dosage"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                defaultValue={selectedCard.dosage}
                fullWidth
                select
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              >
                {fetchDosage(watch("product_id"), selectedCard.program_id)
                  .length > 0 &&
                  !masterDropdownDataLoading &&
                  fetchDosage(watch("product_id"), selectedCard.program_id).map(
                    (option) => (
                      <MenuItem key={option.program_id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    )
                  )}
              </TextField>
            )}
          />
        </Grid>

        <Grid xs={6} item>
          <Typography color={"text.secondary"} variant="caption">
            Prescription Date
          </Typography>
          <Controller
            name="prescription_date"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="ll"
                  value={value}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  onChange={onChange}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        {getDomain() == DomainName.ASTELLAS && (
          <Grid xs={6} item>
            <Typography color={"text.secondary"} variant="caption">
              Transplant Date
            </Typography>
            <Controller
              name="transplant_date"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="ll"
                    value={value}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                    onChange={onChange}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Treatment Start Date
          </Typography>
          <Controller
            name="treatment_start_date"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="ll"
                  value={value}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  onChange={onChange}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography color={"text.secondary"} variant="caption">
            Comments
          </Typography>
          <Controller
            name="comments"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                rows={4}
                multiline
                fullWidth
                size="small"
                aria-multiline
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default EisaiEditProgram;
