import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";

import { MasterDropdownData } from "../../domain/usages/master-dropdown-data";

export class RemoteMasterDropdownData implements MasterDropdownData {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(programId: string, params?: MasterDropdownData.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":programId", programId),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: false,
    });
    if (httpResponse && httpResponse.status === 200) {
      return httpResponse.data;
    } else if(!httpResponse){
      return 404
    }
  }
}
