import StaticProgram from "./StaticProgram";
import EditPrograms from "./EditPrograms";
import { useProgramDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import OrderPlacingTabs from "./OrderPlacingTabs";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { Program } from "../../../../../constants/enums/program";
import EisaiStaticProgram from "./eisai/EisaiStaticProgram";
import EisaiEditProgram from "./eisai/EisaiEditProgram";
import { useEffect } from "react";

const ProgramDetails: React.FC = () => {
  const { value, fetchMasterDropdownData } = useProgramDetailStore();
  const { selectedCard } = useEnrollmentMainStore();

  useEffect(() => {
    if (selectedCard.program_id) {
      fetchMasterDropdownData(selectedCard.program_id);
    }
  }, [selectedCard.program_id]);

  return (
    <>
      {value == 0 && selectedCard.program_id.toString() == Program.EISAI ? (
        <EisaiStaticProgram />
      ) : (
        value == 0 && <StaticProgram />
      )}
      {value == 1 && <OrderPlacingTabs />}
      {value == 2 && selectedCard.program_id.toString() == Program.EISAI ? (
        <EisaiEditProgram />
      ) : (
        value == 2 && <EditPrograms />
      )}
    </>
  );
};
export default ProgramDetails;
