import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  APPOINTMENT_SERVICE_STAGING_URL,
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  CalendarEntry,
  FetchCalendarCountResponse,
} from "../../../domain/models/appointment-module/fetch-calendar-count-response";
import { RemoteFetchCalendarCount } from "../../../data/usecases/appointment-module/remote-fetch-calendar-count";
import { Constant } from "../../../constants/constant";

interface FetchCalendarCountSliceInterface {
  fetchCalendarCount: Function;
  fetchCalendarCountLoading: boolean;
  calendarCountData: CalendarEntry[];
}

const initialStates = {
  fetchCalendarCountLoading: false,
  calendarCountData: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchCalendarCountSlice: StateCreator<
  FetchCalendarCountSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchCalendarCount: async (month_year: string, status: string) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });
    const remoteFetchCalendarCount = new RemoteFetchCalendarCount(
      `${APPOINTMENT_SERVICE_STAGING_URL}${Endpoints.FETCH_APPOINTMENT_CALENDAR_COUNT}`,
      axiosHttpClient
    );

    const payload = {
      month_year,
      status: status,
    };

    set(() => ({ fetchCalendarCountLoading: true }));

    let result: FetchCalendarCountResponse =
      await remoteFetchCalendarCount.fetch(payload);

    if (result) {
      if (result.success) {
        set(() => ({ calendarCountData: result.calendar }));
        set(() => ({ fetchCalendarCountLoading: false }));
      } else {
        Swal.fire("Fetching Calander count failed.", "", "error");
      }
    } else {
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
    }
  },
});
