import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  APPOINTMENT_SERVICE_STAGING_URL,
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Constant } from "../../../constants/constant";
import { RemoteConfirmAppointment } from "../../../data/usecases/appointment-module/remote-confirm-appointment";
import { CancelAppointmentResponse } from "../../../domain/models/appointment-module/cancel-appointment-response";
import { useAppointmentDetailsStore } from "../patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";

interface confirmAppointmentSliceInterface {
  confirmAppointment: Function;
  confirmAppointmentLoading: FetchState;
}

const initialStates = {
  confirmAppointmentLoading: FetchState.DEFAULT,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useConfirmAppointmentSlice: StateCreator<
  confirmAppointmentSliceInterface
> = (set, get) => ({
  ...initialStates,
  confirmAppointment: async (appointment_id: number) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });
    const remoteConfirmAppointment = new RemoteConfirmAppointment(
      `${APPOINTMENT_SERVICE_STAGING_URL}${Endpoints.CONFIRM_APPOINTMENT}`,
      axiosHttpClient
    );
    Swal.fire({
      title: "Response Loading...",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    set(() => ({ confirmAppointmentLoading: FetchState.LOADING }));
    let result: CancelAppointmentResponse =
      await remoteConfirmAppointment.confirm(appointment_id, {});

    if (result) {
      if (result.success) {
        set(() => ({ confirmAppointmentLoading: FetchState.SUCCESS }));
        useAppointmentDetailsStore
          .getState()
          .fetchAppointmentsById(appointment_id);

        Swal.fire({
          icon: "success",
          title: result.message,
          timer: 2000,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      } else {
        Swal.fire(result.errors.message, "", "error");
      }
    } else {
      Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
    }
  },
});
