import { createElement, useEffect } from "react";
import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import indexRoutes, { pageRoutes } from "./routes";
import axios from "axios";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        // If the response is successful, just return it
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 403) {
          // If the API response has a 403 status code, navigate to the login page
          navigate(pageRoutes.loginPage);
        }
        // Return the error to handle it later if necessary
        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Navigate to={pageRoutes.loginPage} />} />
      {indexRoutes.map((prop, key) => {
        return (
          <Route
            key={key}
            path={prop.path}
            element={createElement(prop.component)}
          >
            {prop.children?.map((prop, key) => {
              return (
                <Route
                  key={key}
                  path={prop.path}
                  element={createElement(prop.component)}
                ></Route>
              );
            })}
          </Route>
        );
      })}
    </Routes>
  );
}

export default App;
