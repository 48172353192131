import {
  Card,
  CardHeader,
  Avatar,
  Stack,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { usePersonalDetailStore } from "../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useEnrollmentMainStore } from "../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import EditIcon from "@mui/icons-material/Edit";
import { useAppointmentDetailsStore } from "../../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import { toTitleCase } from "../../../../domain/Utils";

interface PatientBasicDetailsInterface {
  activeTab: string;
}

const PatientBasicDetails: React.FC<PatientBasicDetailsInterface> = ({
  activeTab,
}) => {
  const enrollmentStatusData = [
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Closed",
      value: "closed",
    },
    {
      name: "Discontinued",
      value: "discontinued",
    },
    {
      name: "Cancelled",
      value: "cancelled",
    },
    {
      name: "Non Contactable",
      value: "non_contactable",
    },
  ];

  const { selectedPatientPersonalDetails, updatePatientStatus } =
    usePersonalDetailStore();
  const { sendConsentSMS, selectedCard } = useEnrollmentMainStore();
  const [currentEnrollmentStatus, setCurrentEnrollmentStatus] =
    useState<String>("");
  const [showEditEnrollmentStatus, setShowEditEnrollmentStatus] =
    useState<boolean>(false);
  const { openCreateAppointment, handleAddAppointmentClick } =
    useAppointmentDetailsStore();

  const handleSendConsentSMS = () => {
    sendConsentSMS(selectedCard.id);
  };

  useEffect(() => {
    setCurrentEnrollmentStatus(selectedCard.status);
  }, []);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: " 1px solid #D0CFCF",
          borderRadius: " 12px",
          marginTop: "14px",
          cursor: "pointer",
          paddingRight: "26px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <CardHeader
            avatar={
              <Avatar
                sx={{
                  bgcolor: "#6750A4",
                }}
              >
                {selectedPatientPersonalDetails?.fullName &&
                  selectedPatientPersonalDetails?.fullName[0]}
              </Avatar>
            }
            title={
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                gap={2}
                width={"100%"}
              >
                <Typography>
                  {selectedPatientPersonalDetails?.fullName}
                </Typography>
                {selectedCard.consent_acknowledgement != 1 && (
                  <Tooltip title={"click to send consent SMS"}>
                    <Box border={"1px solid #3F51B7"} borderRadius={"50%"}>
                      <IconButton
                        color="secondary"
                        onClick={handleSendConsentSMS}
                      >
                        <PendingActionsIcon color="secondary" />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            }
            titleTypographyProps={{
              fontFamily: "Segoe UI",
              fontWeight: 500,
              fontSize: "16px",
            }}
            // subheader={selectedPatientPersonalDetails?.mobile}
            subheaderTypographyProps={{
              fontFamily: "Segoe UI",
            }}
          />
          {!showEditEnrollmentStatus && selectedCard.status && (
            <Stack alignItems={"center"} flexDirection={"row"} gap={1}>
              <Typography fontWeight={660} variant="subtitle2">
                Current Status: {toTitleCase(selectedCard.status)}
              </Typography>
              <IconButton onClick={() => setShowEditEnrollmentStatus(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Stack>
          )}

          {(showEditEnrollmentStatus || selectedCard.status == null) && (
            <TextField
              size="small"
              select
              label="Current Status"
              sx={{ width: "35%" }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: currentEnrollmentStatus &&
                  currentEnrollmentStatus != selectedCard.status && (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        color={"secondary"}
                        size={"small"}
                        onClick={() => {
                          let payload = {
                            status: currentEnrollmentStatus,
                          };
                          updatePatientStatus(selectedCard.id, payload);
                        }}
                      >
                        <Typography variant="subtitle2" color="white">
                          Update
                        </Typography>
                      </Button>
                    </InputAdornment>
                  ),
              }}
              value={currentEnrollmentStatus}
              onChange={(e) => setCurrentEnrollmentStatus(e.target.value)}
            >
              {enrollmentStatusData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Stack>
      </Card>
    </>
  );
};
export default PatientBasicDetails;
