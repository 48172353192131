import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useConsentStore } from "../../store/patientConsentStore/consentStore";
import { GenerateSignedUrlForDocumentUpload } from "../../domain/usages/generate-signed-url-for-document-upload";
import { UploadFile } from "../../domain/usages/upload-file";
import { ConfirmDocumentUpload } from "../../domain/usages/confirm-document-upload";
import Swal from "sweetalert2";
import FormLoading from "../components/Loading/FormLoading";
import { Program } from "../../constants/enums/program";
import OsteoEnrollmentForm from "../components/program/OsteoEnrollmentForm";
import AstellasEnrollmentForm from "../components/program/AstellasEnrollmentForm";
import { AcceptPatientConsent } from "../../domain/usages/accept-patient-consent";
import { getDomain } from "../../domain/Utils";
import DomainName from "../../domain/domain";
import EisaiEnrollmentForm from "../components/program/EisaiEnrollmentForm";

type Props = {
  generateUploadURL: GenerateSignedUrlForDocumentUpload;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmDocumentUpload;
};

const PatientProgramConsentPage: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
}) => {
  const { token, id } = useParams();
  const {
    getEnrolmentByConsentTokenDetails,
    consentTokenEnrolmentDetails,
    acceptPatientConsentSliceType,
  } = useConsentStore();

  useEffect(() => {
    if (token) {
      getEnrolmentByConsentTokenDetails(token);
    } else {
      Swal.fire("Authentication Failed", "", "error");
    }
  }, [token]);

  const handleAcceptConsent = (payload: AcceptPatientConsent.Params) => {
    if (token && payload) {
      acceptPatientConsentSliceType(token, payload);
    }
  };

  return (
    <>
      {!consentTokenEnrolmentDetails && <FormLoading />}
      {consentTokenEnrolmentDetails &&
        consentTokenEnrolmentDetails.program_id &&
        consentTokenEnrolmentDetails.program_id.toString() == Program.OSTEO &&
        getDomain() == DomainName.OSTEO && (
          <OsteoEnrollmentForm
            generateUploadURL={generateUploadURL}
            uploadFile={uploadFile}
            confirmFileUpload={confirmFileUpload}
            programId={consentTokenEnrolmentDetails.program_id.toString()}
            successCallback={handleAcceptConsent}
            title={"Program Consent Form"}
            isConsent={true}
          />
        )}
      {consentTokenEnrolmentDetails &&
        consentTokenEnrolmentDetails.program_id &&
        consentTokenEnrolmentDetails.program_id.toString() ==
          Program.ASTELLAS &&
        getDomain() == DomainName.ASTELLAS && (
          <AstellasEnrollmentForm
            generateUploadURL={generateUploadURL}
            uploadFile={uploadFile}
            confirmFileUpload={confirmFileUpload}
            programId={consentTokenEnrolmentDetails.program_id.toString()}
            successCallback={handleAcceptConsent}
            title={"Program Consent Form"}
            isConsent={true}
          />
        )}
      {consentTokenEnrolmentDetails &&
        consentTokenEnrolmentDetails.program_id &&
        consentTokenEnrolmentDetails.program_id.toString() == Program.EISAI &&
        getDomain() == DomainName.EISAI && (
          <EisaiEnrollmentForm
            generateUploadURL={generateUploadURL}
            uploadFile={uploadFile}
            confirmFileUpload={confirmFileUpload}
            programId={consentTokenEnrolmentDetails.program_id.toString()}
            successCallback={handleAcceptConsent}
            title={"Program Consent Form"}
            isConsent={true}
          />
        )}
    </>
  );
};

export default PatientProgramConsentPage;
